import React from "react";
import "./contact.css";
import Header from "../navbar/Navbar";
import ContactFooter from "./FooterContact.js";
import { Helmet, HelmetProvider } from "react-helmet-async";
import WEBSITE from "../../config/websiteData";

function Contact() {
  const handleFormSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{WEBSITE.title} | Contact Us</title>
        </Helmet>
        <Header />
        <div className="contact">
          <span className="contact-heading">CONTACT US</span>
          <div className="contact-form">
            <span className="contact-formDesc">
              Have something interesting to say or you require some help? Write
              us a message and we will get back to you as soon as possible. We
              are looking forward to hear from you.
            </span>
            <form style={{ paddingTop: "10px" }} onSubmit={handleFormSubmit}>
              <ul className="form-style-1">
                <li>
                  <label>
                    Full Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="field1"
                    className="field-divided"
                    placeholder="First"
                  />{" "}
                  <input
                    type="text"
                    name="field2"
                    className="field-divided"
                    placeholder="Last"
                  />
                </li>
                <li>
                  <label>
                    Email <span className="required">*</span>
                  </label>
                  <input type="email" name="field3" className="field-long" />
                </li>
                <li>
                  <label>Subject</label>
                  <select name="field4" className="field-select">
                    <option value="Advertise">Advertise</option>
                    <option value="Partnership">Partnership</option>
                    <option value="Support">Support</option>
                  </select>
                </li>
                <li>
                  <label>
                    Your Message <span className="required">*</span>
                  </label>
                  <textarea
                    name="field5"
                    id="field5"
                    className="field-long field-textarea"></textarea>
                </li>
                <li>
                  <button type="submit" className="contact-button">
                    SEND MESSAGE
                  </button>
                </li>
              </ul>
            </form>
          </div>
        </div>
        <ContactFooter />
      </HelmetProvider>
    </>
  );
}

export default Contact;
