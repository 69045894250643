import React from "react";
import "./Home.css";
import phone from "../../assets/icons/phone.png";
import { useMediaQuery } from "react-responsive";
import WEBSITE from "../../config/websiteData";

function HomeVision() {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div className="home-vision">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span
          style={{
            textAlign: "center",
            fontSize: isMobile ? "5rem" : "14rem",
            color: "#f0f0f0",
          }}>
          OUR VISION
        </span>
      </div>
      <div className="home-vision2">
        <div className="home-visionExcerpt">
          <span>
            We're on an exhilarating mission to elevate the {WEBSITE.name}
            universe to unparalleled heights. Beyond our groundbreaking NFTs and
            tokens, we're pioneering a revolutionary fusion of the tangible and
            the virtual. Imagine stepping into a candy store, making a purchase,
            and simultaneously unlocking AUTOSHOW rewards, exclusive{" "}
            {WEBSITE.name}, and immersive gaming experiences in the Metaverse.
            Our vision is to craft a seamless blend of the Metaverse and
            reality, culminating in a phenomenal creation. Every {WEBSITE.name}{" "}
            NFT you acquire not only propels us closer to this dream but also
            contributes to a charity dear to us.
            <br />
            <br />
            Your role in this journey is paramount. Your insights, your passion,
            and your commitment are the catalysts driving us forward. We invite
            you to co-create this vision with us. Introducing 'The{" "}
            {WEBSITE.name} Syndicate' - our upcoming DAO where your voice shapes
            our trajectory. We're staunch advocates of a decentralized future
            and are eager to manifest its potential in the tangible realm. And
            let's be honest, who can resist the allure of candy?
            <br />
            <br />
            <br />
            <span style={{ color: "#FE5659", fontWeight: "bold" }}>
              Our ethos revolves around a community-centric approach, valuing
              feedback at every twist and turn. This ensures our endeavors
              resonate deeply with the aspirations of our community.
            </span>
          </span>
        </div>
        <div className="home-visionCenter"></div>
        <div className="home-visionImage">
          <img
            src={phone}
            alt=""
            className="pb-10 bg"
            width="90%"
            height="90%"
          />
        </div>
      </div>
    </div>
  );
}

export default HomeVision;
