import React from "react";
import tokend from "../../assets/icons/tokend.svg";
import commallo from "../../assets/icons/commallo.svg";
import WEBSITE from "../../config/websiteData";

function Tokenomics() {
  return (
    <>
      <div className="home-tokenomicsTitle">
        <span className="homeCollections-h1">TOKENOMICS</span>
      </div>

      <div className="home-tokenomics">
        <span
          className="home-tokenomicsExcerpt mt-4"
          style={{ color: "#ffcd3c", fontWeight: "bold" }}>
          Introducing the future of automotive ownership and trading: the
          {WEBSITE.name} Token. Our tokenomics is designed to revolutionize the
          way we perceive, own, and trade vehicles.
        </span>
        <div className="home-tokenomicsPie">
          <div className="home-tokenomicsPieLeft">
            <span>TOKEN DISTRIBUTION</span>
            <img src={tokend} alt="" />
          </div>
          <div className="home-tokenomicsPieCenter"></div>
          <div className="home-tokenomicsPieRight">
            <span>COMMUNITY ALLOCATION</span>
            <img src={commallo} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Tokenomics;
