const newsdata = [
  {
    // image: "add your URLK here",
    date: "August 20",
    heading: "Automobile SHOW NFT: The Future of Car Shows!",
    desc: "Experience the world of automobiles like never before with our latest NFT project. Dive into the virtual realm of car shows on the Ethereum Mainnet.",
    link: "https://www.autoshow-nft.com/",
  },
  {
    date: "August 19",
    heading: "Revving Up: Automobile SHOW NFT Gears for Launch",
    desc: "Get ready to witness a revolution in the automobile industry. Our NFT project promises a unique blend of technology and passion for cars.",
    link: "https://www.autoshow-nft.com/news",
  },
  {
    date: "August 18",
    desc: "Discover how the fusion of blockchain and automobile enthusiasm is set to redefine the way we experience car shows.",
    link: "https://www.autoshow-nft.com/blog",
  },
  {
    date: "August 17",
    heading: "Behind the Scenes: Making of the Automobile SHOW NFT",
    desc: "Join us on a journey as we unveil the creative and technical process behind our groundbreaking NFT project.",
    link: "https://www.autoshow-nft.com/behind-the-scenes",
  },
  {
    date: "August 16",
    heading: "Automobile Enthusiasts Embrace the NFT Wave",
    desc: "See how car lovers worldwide are reacting to the upcoming launch of our Automobile SHOW NFT on the Ethereum Mainnet.",
    link: "https://www.autoshow-nft.com/reviews",
  },
  {
    heading: "How to Participate in the Automobile SHOW NFT Launch",
    date: "August 15",
    desc: "Eager to be part of the NFT revolution in the automobile world? Here’s a step-by-step guide to get you started.",
    link: "https://www.autoshow-nft.com/guide",
  },
  {
    date: "August 14",
    heading: "Sneak Peek: Exclusive NFTs from the Automobile SHOW",
    desc: "Get an exclusive preview of some of the most sought-after NFTs from our upcoming automobile project.",
    link: "https://www.autoshow-nft.com/preview",
  },
  {
    date: "August 13",
    heading: "Partnerships and Collaborations: Automobile SHOW NFT",
    desc: "Learn about our strategic partnerships that are set to elevate the Automobile SHOW NFT experience.",
    link: "https://www.autoshow-nft.com/partnerships",
  },
  {
    heading: "FAQs: All You Need to Know About Automobile SHOW NFT",
    date: "August 12",
    desc: "Got questions about our NFT project? Dive into our comprehensive FAQ section for all the answers.",
    link: "https://www.autoshow-nft.com/faqs",
  },
];

export default newsdata;
