import React from "react";
import "./teamfooter.css";
import discord from "../../assets/icons/discordftr.svg";
import instagram from "../../assets/icons/instagramftr.svg";
import twitter from "../../assets/icons/x.svg";
import linkedin from "../../assets/icons/linkedin.svg";
import reddit from "../../assets/icons/reddit.svg";
import telegram from "../../assets/icons/telegram.svg";
import medium from "../../assets/icons/medium.svg";
import SOCIALS from "../../config/socials.json";
import WEBSITE from "../../config/websiteData";

function Footer() {
  return (
    <>
      <div className="footer-team">
        <span className="footer-h1">{WEBSITE.h1}</span>
        <span className="footer-h2">{WEBSITE.h2}</span>
        <div className="footer-social-team">
          <a href={SOCIALS.discord} target="_blank" rel="noreferrer">
            <img style={{ height: "2.5rem" }} src={discord} alt="" />
          </a>
          <a href={SOCIALS.twitter} target="_blank" rel="noreferrer">
            <img style={{ height: "2.5rem" }} src={twitter} alt="" />
          </a>
          <a href={SOCIALS.instagram} target="_blank" rel="noreferrer">
            <img style={{ height: "2.5rem" }} src={instagram} alt="" />
          </a>
          <a href={SOCIALS.linkedin} target="_blank" rel="noreferrer">
            <img style={{ height: "2.5rem" }} src={linkedin} alt="" />
          </a>
          <a href={SOCIALS.reddit} target="_blank" rel="noreferrer">
            <img style={{ height: "2.5rem" }} src={reddit} alt="" />
          </a>
          <a href={SOCIALS.telegram} target="_blank" rel="noreferrer">
            <img style={{ height: "2.5rem" }} src={telegram} alt="" />
          </a>
          <a href={SOCIALS.medium} target="_blank" rel="noreferrer">
            <img style={{ height: "2.5rem" }} src={medium} alt="" />
          </a>
        </div>
        <div className="footer-last-team">
          <span>Copyright © 2024 </span>
          <span>
            <a
              href="https://codecanyon.net/user/web3market/portfolio"
              target="_blank"
              rel="noreferrer">
              Terms & Conditions
            </a>
          </span>
        </div>
      </div>
    </>
  );
}

export default Footer;
