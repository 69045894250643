import React from "react";
import defaultimg from "../../assets/images/news.png";
import "./news.css";

const currentPath = window.location.href + "#";

const NewsItem = ({ data }) => (
  <div className="news-item">
    <img
      className="news-item-img"
      src={`${data.image || defaultimg}`}
      alt=""></img>
    <span className="news-date">{data.date}</span>
    <div className="news-item-content">
      <span className="news-item-heading">{data.heading}</span>
      <span className="news-item-desc">{data.desc}</span>
      <span className="news-item-link">
        {/* if no Link to the article is posted, it will use default browser link and add # at the end */}
        <a href={data.link || currentPath} target="_blank" rel="noreferrer">
          Read article
        </a>
      </span>
    </div>
  </div>
);

export default NewsItem;
