import React from "react";
import { FaEthereum } from "react-icons/fa";
import carcollect from "../../assets/images/car5.png";
import { useContext, useState, useEffect } from "react";
import { ethers } from "ethers";
import { GlobalContext } from "./../../context/GlobalContext";
import Web3Modal from "web3modal";
import CONFIG from "./../../config/config.json";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { FaWallet, FaShoppingCart } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import WEBSITE from "../../config/websiteData";

const providerOptions = {
  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: "{WEBSITE.name} - NFT Platform",
      infuraId: process.env.REACT_APP_INFURA_PROJECT_ID,
    },
  },
  binancechainwallet: {
    package: true,
  },
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: process.env.REACT_APP_INFURA_PROJECT_ID,
    },
  },
};

function AboutCollections() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { account, web3, addAccount, addNetwork, addWeb3 } =
    useContext(GlobalContext);

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const connectWallet = async () => {
    try {
      const web3modal = new Web3Modal({
        cacheProvider: true,
        providerOptions,
      });
      const instance = await web3modal.connect();
      const provider = new ethers.BrowserProvider(instance);
      const signer = await provider.getSigner();
      const address = signer.address;

      addAccount({ id: address });

      const networkId = await provider.getNetwork();
      addNetwork(networkId);
      addWeb3(provider);

      if (networkId.chainId === CONFIG.CHAIN_ID) {
        setError(false);
        setErrorMsg("");
      } else {
        setError(true);
        setErrorMsg(
          "Contract not deployed to current network, please change network to Ethereum Mainnet"
        );
      }
    } catch (error) {
      if (
        error === "Modal closed by user" ||
        error.toString().includes("Error: User Rejected")
      ) {
        // Do nothing if the error message matches "Modal closed by user"
        return;
      }
      console.error("Error connecting to wallet:", error);
      setError(true);
      setErrorMsg("Failed to connect to wallet. Please try again.");
    }
  };

  useEffect(() => {
    (async () => {
      if (localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER"))
        await connectWallet();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        addAccount(accounts[0]);
      });
      window.ethereum.on("chainChanged", (chainId) => {
        window.location.reload();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, web3]);

  return (
    <div className="home-collections">
      <div className="home-collections-left">
        <span className="homeCollections-h1 mt-4">
          ABOUT
          <br />
          COLLECTIONS
        </span>
        <span className="homeCollectionsExcerpt" style={{ marginTop: "30px" }}>
          <span style={{ color: "#ffcd3c", fontWeight: "bold" }}>
            The {WEBSITE.name}pieces are preserved as ERC-721 tokens on the
            Ethereum blockchain and anchored on IPFS. (Refer to Documentation
            and Verification.) To enter exclusive zones like The Garage
            Showcase, holders must be logged into their digital wallets, such as
            Metamask or WalletConnect.
          </span>
          <br />
          <br />
          Every {WEBSITE.name}piece is distinct and meticulously crafted from
          over 100 potential features, including paint colors, wheel designs,
          and interior finishes. All our Showpiece Cars come in 6 exclusive
          editions: Classic Vintage, Modern Sport, Luxury Sedan, Electric
          Future, Off-Road Beast, and Sleek Convertible. Each Showpiece Car is a
          testament to automotive excellence, with some being exceptionally rare
          and coveted. We've also reserved 20 iconic Showpiece Cars, each with a
          storied legacy, exclusively for those who acquire one of these
          magnificent machines. Dive into the world of automotive elegance and
          own a piece of history with our {WEBSITE.name} NFTs.
        </span>
        <form target="_blank" action="https://google.com">
          <button className="buttonAboutCollection mt-4">
            LEARN MORE ABOUT OUR COLLECTION
          </button>
        </form>
      </div>
      <div className="home-collections-center"></div>
      <div className="home-collections-right">
        <div className="card">
          <img src={carcollect} alt="" />
          <div className="card-title">{WEBSITE.name} NFT</div>
          <div className="card-subtitle">
            Our NFT's are stored on the Ethereum blockchain and anchored on
            IPFS. You can buy them easily on our website or on OpenSea.
          </div>
          <hr className="card-divider" />
          <div className="card-footer">
            <div className="card-price">
              <span className="price-label">
                <FaEthereum />
                ETH
              </span>
              0.1
            </div>
            {account ? (
              <>
                <button className="card-btn text-black">
                  <FaShoppingCart size={isMobile ? 15 : 21} />
                  BUY NFT
                </button>
              </>
            ) : (
              <button className="card-btn text-black" onClick={connectWallet}>
                <FaWallet size={isMobile ? 15 : 21} />
                CONNECT WALLET
              </button>
            )}
          </div>
        </div>
      </div>
      {error && (
        <div id="snackbar" className="show">
          {errorMsg}
        </div>
      )}
    </div>
  );
}

export default AboutCollections;
