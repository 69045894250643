import React from "react";
import defaultimg from "../../assets/images/press.png";
import "./Press.css";

const currentPath = window.location.href + "#";

const PressItem = ({ data }) => (
  <div className="press-item">
    <div className="press-item-content">
      <img
        className="press-item-img"
        src={`${data.image || defaultimg}`}
        alt=""></img>
      <span className="press-item-desc">{data.desc}</span>
      <span className="press-item-link">
        <a href={data.link || currentPath} target="_blank" rel="noreferrer">
          Read article
        </a>
      </span>
    </div>
  </div>
);

export default PressItem;
