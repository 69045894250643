import React from "react";
import NewsData from "./newsdata";
import NewsItem from "./newsitem";
import "./news.css";
import Header from "../navbar/Navbar";
import Footer from "../Contact/FooterContact";
import { Helmet, HelmetProvider } from "react-helmet-async";
import WEBSITE from "../../config/websiteData";

const News = () =>
  NewsData.length > 0 && (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{WEBSITE.title} | News</title>
        </Helmet>
        <Header />
        <div className="news">
          <span className="news-heading">NEWS & ARTICLES</span>
          <div className="news-container">
            {NewsData.map((newsdata, idx) => (
              <NewsItem data={newsdata} key={idx} />
            ))}
          </div>
        </div>
        <Footer />
      </HelmetProvider>
    </>
  );

export default News;
