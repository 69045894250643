import React from "react";
import Header from "../navbar/Navbar.js";
import TeamFooter from "./TeamFooter.js";
import "./team.css";
import andrew from "../../assets/icons/andrew.jpg";
import mark from "../../assets/icons/mark.jpg";
import { Helmet, HelmetProvider } from "react-helmet-async";
import WEBSITE from "../../config/websiteData";
function team() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{WEBSITE.title} | Team</title>
        </Helmet>
        <Header />
        <div className="team">
          <div className="team-left">
            <span className="team-heading">ANDREW FORD</span>
            <span
              className="team-headingExcerpt text-xl"
              style={{ marginTop: "15px", color: "#E6E6E6" }}>
              CEO
            </span>
            <span
              className="team-headingExcerpt"
              style={{
                marginTop: "30px",
                marginBottom: "30px",
                color: "#E6E6E6",
              }}>
              Hailing from the vibrant landscapes of Spain, Andrew Ford stands
              out as a proficient Web3 developer with a penchant for innovation.
              His extensive knowledge spans multiple programming languages,
              positioning him as a versatile asset to any team. Beyond his
              coding expertise, Andrew's passion for the decentralized web is
              evident in every project he undertakes. <br />
              <br />
              His commitment to pushing the boundaries of what's possible in the
              Web3 space is truly commendable. When he's not immersed in code,
              Andrew is often found exploring new technological horizons, always
              eager to learn and grow. A true visionary in the field, Andrew
              Ford is the embodiment of the future of web development.
            </span>
          </div>
          <div className="team-right">
            <img
              style={{ height: "500px", marginLeft: "auto" }}
              className="card-team"
              src={andrew}
              alt=""
            />
          </div>
        </div>
        <div
          style={{ backgroundColor: "#F3454A", paddingTop: "140px" }}
          className="team-bot">
          <div className="team-left">
            <span className="team-heading">MARK EDEN</span>
            <span
              className="team-headingExcerpt text-xl"
              style={{ marginTop: "15px", color: "#E6E6E6" }}>
              CTO
            </span>
            <span
              className="team-headingExcerpt"
              style={{ color: "#E6E6E6", marginTop: "30px" }}>
              Our team has over 25 years of experience in digital and product
              development, including toys, virtual worlds, and gaming. Since
              1983, the group has been pushing the boundaries of technology. We
              also introduce the new Chocolate Token (CHOCO), which aids in the
              expansion of the Chocolate Monkey Metaverse. We combed the globe
              for the greatest professionals we could find to assist us in
              making Chocolate Monkeys a success. This group has decades of
              industry experience.
              <br />
              <br />
              Our community is obsessed with the overall user experience because
              we recognize that our users are a true community. We are committed
              to providing real, valuable rewards to our users while also
              utilizing various result-oriented marketing tools to grow our
              community. The Chocolate Monkey community invites you all to join
              us on this journey.
            </span>
          </div>
          <div className="team-right">
            <img
              style={{ height: "500px", marginLeft: "auto", marginTop: "40px" }}
              className="card-team"
              src={mark}
              alt=""
            />
          </div>
        </div>
        <TeamFooter />
      </HelmetProvider>
    </>
  );
}

export default team;
