import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./component/home/Home";
import Team from "./component/Team/Team";
import Contact from "./component/Contact/Contact";
import Mint from "./component/Mint/Mint";
import ScrollToTop from "./component/ScrollToTop";
import News from "./component/NewsArticles/News";
import Press from "./component/PressRelease/Press";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/team" element={<Team />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/mint" element={<Mint />} />
          <Route path="/news" element={<News />} />
          <Route path="/press" element={<Press />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
