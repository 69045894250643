const data = [
    {
        text: 'Token Development Begins',
        desc: 'Initiation of the Automobile NFT token development on the Ethereum testnet.'
    },
    {
        text: 'Website Launch',
        desc: 'Official launch of the Automobile NFT project website, introducing the vision and roadmap.'
    },
    {
        text: 'Whitepaper Release',
        desc: 'Publication of the detailed whitepaper, explaining the tokenomics, utility, and future plans.'
    },
    {
        text: 'Social Media Channels Go Live',
        desc: 'Launch of official social media channels for community engagement and updates.'
    },
    {
        text: 'DApp Development Starts',
        desc: 'Beginning of the decentralized application (DApp) development for NFT minting and trading.'
    },
    {
        text: 'Token Pre-Sale',
        desc: 'Exclusive pre-sale event for early supporters and community members.'
    },
    {
        text: 'Token Launch on Ethereum Mainnet',
        desc: 'Official launch of the Automobile NFT token on the Ethereum mainnet.'
    },
    {
        text: 'Partnership Announcements',
        desc: 'Revealing strategic partnerships with automobile brands and other tech companies.'
    },
    {
        text: 'DApp Beta Testing',
        desc: 'Inviting community members for beta testing of the DApp, gathering feedback and making improvements.'
    },
    {
        text: 'Community AMA Sessions',
        desc: 'Regular "Ask Me Anything" sessions with the core team, addressing queries and sharing updates.'
    },
    {
        text: 'DApp Official Launch',
        desc: 'Full-fledged launch of the DApp, enabling users to mint, buy, and sell automobile NFTs.'
    },
    {
        text: 'NFT Airdrops',
        desc: 'Special NFT airdrops for early supporters and active community members.'
    },
    {
        text: 'Mobile App Development Begins',
        desc: 'Start of the mobile application development to enhance user experience on-the-go.'
    },
    {
        text: 'Global Marketing Campaign',
        desc: 'Kick-off of a global marketing campaign to raise awareness and attract a wider audience.'
    },
]

export default data;
