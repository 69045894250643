import React from "react";
import PressData from "./PressData";
import PressItem from "./PressItem";
import "./Press.css";
import Header from "../navbar/Navbar";
import Footer from "../Contact/FooterContact";
import { Helmet, HelmetProvider } from "react-helmet-async";
import WEBSITE from "../../config/websiteData";
const Press = () =>
  PressData.length > 0 && (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{WEBSITE.title} | Press</title>
        </Helmet>
        <Header />
        <div className="press">
          <span className="press-heading">PRESS RELEASE</span>
          <div className="press-container">
            {PressData.map((pressdata, idx) => (
              <PressItem data={pressdata} key={idx} />
            ))}
          </div>
        </div>
        <Footer />
      </HelmetProvider>
    </>
  );

export default Press;
