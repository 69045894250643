import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import cmlogo from "../../../src/assets/images/logo.png";
import Hamburger from "./Hamburger.js";
import pdf from "./../../assets/whitepaper/whitepaper.pdf";
import "./Navbar.css";

function Navbar() {
  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  });
  const [navbar, setNavbar] = useState(false);
  return (
    <div className={navbar ? "header header-active" : "header"}>
      <div className="header-left">
        <div>
          <Link to="/">
            <img className="header-icon h-1/4 p-2" src={cmlogo} alt="example" />
          </Link>
        </div>
      </div>
      <div className="header-right">
        <div className="header-left-menu">
          <Link className="navLink" aria-current="page" to="/">
            Home
          </Link>
          <Link className="navLink" aria-current="page" to="/team">
            Team
          </Link>
          <Link className="navLink" aria-current="page" to="/mint">
            Mint
          </Link>
          <Link
            className="navLink"
            aria-current="page"
            to={pdf}
            target="_blank"
            rel="noreferrer">
            Whitepaper
          </Link>
          <Link className="navLink" aria-current="page" to="/contact">
            Contact
          </Link>
          <Link className="navLink" aria-current="page" to="/news">
            News
          </Link>
          <Link className="navLink" aria-current="page" to="/press">
            Press
          </Link>
        </div>
      </div>
      <Hamburger />
    </div>
  );
}

export default Navbar;
