import React from "react";
import { useMediaQuery } from "react-responsive";
import "./Home.css";
import discordwhite from "../../assets/icons/discordwhite.svg";
import instagramwhite from "../../assets/icons/instagramwhite.svg";
import twitterwhite from "../../assets/icons/x.svg";
import aboutimg1 from "../../assets/icons/nft.png";
import WEBSITE from "../../config/websiteData";

export default function HomeAbout() {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div className="home-about">
      <span className="homeAbout-h1">ABOUT US</span>
      <div className="home-about2">
        <div className="home-aboutFollow">
          <span style={{ fontSize: "16px", marginBottom: "30px" }}>
            Follow us on:
          </span>
          <span>
            <a href="https://x.com/" target="_blank" rel="noreferrer">
              <span>
                <img src={twitterwhite} alt="" /> X (Twitter)
              </span>
            </a>
          </span>
          <span>
            <a href="https://discord.com/" target="_blank" rel="noreferrer">
              <span>
                <img src={discordwhite} alt="" />
                DISCORD
              </span>
            </a>
          </span>
          <span>
            <a
              href="https://www.instagram.com/"
              target="_blank"
              rel="noreferrer">
              <span>
                <img src={instagramwhite} alt="" />
                INSTAGRAM
              </span>
            </a>
          </span>
        </div>
        <div className="home-aboutImage">
          <img src={aboutimg1} alt="NFT" height="115%" width="115%" />
        </div>
        <div
          className={
            isMobile ? "home-aboutExcerpt" : "home-aboutExcerpt ml-12"
          }>
          <span>
            During the early months of 2021, Non-Fungible Tokens, commonly known
            as NFTs, gained significant traction within the cryptocurrency
            circles and projects. Their market valuation has consistently grown,
            now reaching a staggering $20B. As blockchain technology and the
            gaming community continue to expand, the adoption and utilization of
            NFTs are expected to surge.
            <br />
            <br />
            Artistic creations represent a large portion of NFTs. They are
            arguably the most captivating and crucial application of
            non-fungible token mechanisms. NFTs have ushered in a new era for
            the Arts by offering unique ownership possibilities. This paper,
            however, will primarily delve into the monetary benefits derived
            from {WEBSITE.name} NFTs.
            <br />
            <br />
            To reiterate, {WEBSITE.name}'s comprise a set of 4,444 distinct
            digital collectibles anchored to the Ethereum blockchain. Owning a
            {WEBSITE.name} not only grants you a collectible but also acts as a
            pass to the Candy Shop, unlocking various owner privileges,
            unexpected rewards, and additional perks. Additionally, 5% of the
            proceeds from a {WEBSITE.name} NFT sale are donated to charitable
            causes.
          </span>
        </div>
      </div>
    </div>
  );
}
