import React from "react";
import "./mint.css";
import Header from "../navbar/Navbar";
import Footer from "../Contact/FooterContact";
import MintSlider from "./MintSlider";
import BuyMint from "./BuyMint";
import { Helmet, HelmetProvider } from "react-helmet-async";
import WEBSITE from "../../config/websiteData";
function Mint() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{WEBSITE.title} | Mint</title>
        </Helmet>
        <Header />
        <div className="mint">
          <div className="mint-1">
            <span className="mint-h1">{WEBSITE.h1}</span>
            <span className="mint-h2">{WEBSITE.h2}</span>
            <MintSlider />
          </div>
          <div className="mint-itr">
            <span
              style={{
                color: "#f1f1f1",
                fontSize: "40px",
                lineHeight: "40px",
                fontWeight: "bold",
              }}>
              MINTING
              <br /> INSTRUCTIONS
            </span>
            <ul className="mint-list" style={{ marginTop: "30px" }}>
              <li>
                Click the 'CONNECT WALLET' button to link your wallet and then
                follow the MetaMask prompts. Ensure that your MetaMask Wallet is
                set to the Ethereum Mainnet.
              </li>
              <li>
                Select the quantity of NFTs you wish to create using the field
                provided.
              </li>
              <li>
                Press the 'Buy Now' button, and a MetaMask window will appear.
              </li>
              <li>
                Choose 'Accept' followed by 'Confirm' in your MetaMask wallet.
                Continue with the given steps until you see a confirmation
                message.
              </li>
            </ul>
          </div>
        </div>
        <BuyMint />
        <Footer />
      </HelmetProvider>
    </>
  );
}

export default Mint;
