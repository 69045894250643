import React from "react";

import { useContext, useState, useEffect } from "react";
import { ethers } from "ethers";
import { GlobalContext } from "./../../context/GlobalContext";
import Web3Modal from "web3modal";
import CONFIG from "./../../config/config.json";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import { SiOpensea } from "react-icons/si";
import WEBSITE from "./../../config/config.json";

import "./buymint.css";

const providerOptions = {
  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: `${WEBSITE.name} - NFT Platform`,
      infuraId: process.env.REACT_APP_INFURA_PROJECT_ID,
    },
  },
  binancechainwallet: {
    package: true,
  },
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: process.env.REACT_APP_INFURA_PROJECT_ID,
    },
  },
};

function BuyMint() {
  const { account, web3, addAccount, addNetwork, addWeb3 } =
    useContext(GlobalContext);

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [mintCount, setMintCount] = useState(1);
  // const [nftCost, setNftCost] = useState(0.1);
  const nftCost = 0.1;
  const [loading, setLoading] = useState(false);

  const handleBuyNFTs = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  // Auto connect wallet if cached provider exists
  useEffect(() => {
    (async () => {
      if (localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER"))
        await connectWallet();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const connectWallet = async () => {
    try {
      const web3modal = new Web3Modal({
        cacheProvider: true,
        providerOptions,
      });
      const instance = await web3modal.connect();
      const provider = new ethers.BrowserProvider(instance);
      const signer = await provider.getSigner();
      const address = signer.address;

      addAccount({ id: address });

      const networkId = await provider.getNetwork();
      addNetwork(networkId);
      addWeb3(provider);

      if (networkId.chainId === CONFIG.CHAIN_ID) {
        setError(false);
        setErrorMsg("");
      } else {
        setError(true);
        setErrorMsg(
          "Contract not deployed to current network, please change network to Ethereum Mainnet"
        );
      }
    } catch (error) {
      if (
        error === "Modal closed by user" ||
        error.toString().includes("Error: User Rejected")
      ) {
        // Do nothing if the error message matches "Modal closed by user"
        return;
      }
      console.error("Error connecting to wallet:", error);
      setError(true);
      setErrorMsg("Failed to connect to wallet. Please try again.");
    }
  };

  const mintCountOnChange = (e) => {
    const value = parseInt(e.target.value);
    setMintCount(isNaN(value) || value < 1 ? 1 : value);
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        addAccount(accounts[0]);
      });
      window.ethereum.on("chainChanged", (chainId) => {
        window.location.reload();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, web3]);

  return (
    <>
      {account ? (
        <div className="buy-mint">
          {" "}
          <input
            className="input-group-presuffix-number mint-input"
            type="number"
            placeholder="Number of NFT's"
            value={mintCount}
            onChange={mintCountOnChange}
          />
          <div className="input-group-presuffix">
            <span className="input-group-addon prefix">Price: </span>
            <input
              disabled
              className="mint-input"
              type="text"
              name=""
              id=""
              value={(parseFloat(nftCost) * parseInt(mintCount)).toFixed(2)}
              onChange={(e) => console.log(e)}
            />
            <span className="input-group-addon suffix">ETH</span>
          </div>
          {loading ? (
            <button
              disabled
              className="mint-button flex items-center justify-center animate-float transition-transform duration-2000 ease-in-out pl-6 pt-2 pr-6 pb-2">
              Processing...
            </button>
          ) : (
            <button
              className="mint-button flex items-center justify-center animate-float transition-transform duration-2000 ease-in-out pl-6 pt-2 pr-6 pb-2"
              onClick={handleBuyNFTs}>
              BUY NFT'S
            </button>
          )}
          <button className="mint-button flex items-center justify-center animate-float transition-transform duration-2000 ease-in-out pl-6 pt-2 pr-6 pb-2">
            BUY ON <SiOpensea className="mr-1 ml-2" color="#1e81e2" /> OPENSEA
          </button>
        </div>
      ) : (
        <div className="buy-mint-center">
          <button
            className="mint-button flex items-center justify-center animate-float transition-transform duration-2000 ease-in-out pl-6 pt-2 pr-6 pb-2"
            onClick={connectWallet}>
            CONNECT WALLET
          </button>
        </div>
      )}

      <div className="buy-mintM">
        {account ? (
          <>
            <input
              className="input-group-presuffix-number mint-input mint-inputM"
              type="number"
              name=""
              id=""
              placeholder="Number of NFT's"
              value={mintCount}
              onChange={mintCountOnChange}
            />
            <div className="input-group-presuffix input-group-presuffixM">
              <span className="input-group-addon prefix">Price: </span>
              <input
                disabled
                className="mint-input"
                type="text"
                name=""
                id=""
                value={(parseFloat(nftCost) * parseInt(mintCount)).toFixed(2)}
                onChange={(e) => console.log(e)}
              />
              <span className="input-group-addon suffix">ETH</span>
            </div>
            {loading ? (
              <button
                disabled
                style={{ width: "100%" }}
                className="mint-button flex items-center justify-center animate-float transition-transform duration-2000 ease-in-out pl-6 pt-2 pr-6 pb-2">
                Processing...
              </button>
            ) : (
              <button
                className="mint-button flex items-center justify-center animate-float transition-transform duration-2000 ease-in-out pl-6 pt-2 pr-6 pb-2"
                style={{ width: "100%" }}
                onClick={handleBuyNFTs}>
                BUY NFT'S
              </button>
            )}
            <button className="mint-button flex items-center justify-center animate-float transition-transform duration-2000 ease-in-out pl-6 pt-2 pr-6 pb-2">
              BUY ON <SiOpensea className="mr-1 ml-2" color="#1e81e2" /> OPENSEA
            </button>
            <br />
          </>
        ) : (
          <button className="buy-mint-center" onClick={connectWallet}>
            CONNECT WALLET
          </button>
        )}
      </div>
      {error && (
        <div id="snackbar" className="show">
          {errorMsg}
        </div>
      )}
    </>
  );
}

export default BuyMint;
