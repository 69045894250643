import React from "react";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import "./Home.css";
import gif1 from "../../assets/gif/car.svg";
import HomeAbout from "./HomeAbout";
import HomeVision from "./HomeVision";
import AboutCollections from "./AboutCollections";
import Tokenomics from "./Tokenomics";
import Timeline from "../Timeline/Timeline";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { SiOpensea } from "react-icons/si";
import HomeSlider from "./homeSlider";
import WEBSITE from "../../config/websiteData";

function Home() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{WEBSITE.title} | Home</title>
        </Helmet>
        <Navbar />
        <div className="home">
          <div className="home-1">
            <span className="home-h1">{WEBSITE.h1}</span>
            <span className="home-h2">{WEBSITE.h2}</span>
            <img className="home-img1" src={gif1} alt="" />
          </div>
          <span className="home-h3">
            Our project comprises 4,444 NFTs,
            <br />
            which are distinct digital items
            <br />
            linked to the Ethereum blockchain.
          </span>
          <button className="home-button flex items-center justify-center animate-float transition-transform duration-2000 ease-in-out pl-6 pt-2 pr-6 pb-2 uppercase">
            See our collection on{" "}
            <SiOpensea className="mr-1 ml-2" color="#1e81e2" /> OpenSea
          </button>
          <div className="pb-5 sm:pb-40" />
          <HomeSlider />
          <HomeAbout />
          <HomeVision />
          <AboutCollections />
          <Tokenomics />
          <Timeline />
        </div>
        <Footer />
      </HelmetProvider>
    </>
  );
}

export default Home;
