import React from "react";
import "./mintslider.scss";
import img1 from "../../assets/images/car1.png";
import img2 from "../../assets/images/car2.png";
import img3 from "../../assets/images/car3.png";
import img4 from "../../assets/images/car4.png";
import img5 from "../../assets/images/car5.png";
import img6 from "../../assets/images/car6.png";
import img11 from "../../assets/images/car11.png";
import img22 from "../../assets/images/car22.png";
import img33 from "../../assets/images/car33.png";
import img44 from "../../assets/images/car44.png";
import img55 from "../../assets/images/car55.png";
import img66 from "../../assets/images/car66.png";
function MintSlider() {
  return (
    <>
      <div className="logo-slider ">
        <div>
          <img src={img1} alt="" />
          <img src={img2} alt="" />
          <img src={img3} alt="" />
          <img src={img4} alt="" />
          <img src={img5} alt="" />
          <img src={img6} alt="" />
        </div>
        <div>
          <img src={img11} alt="" />
          <img src={img22} alt="" />
          <img src={img33} alt="" />
          <img src={img44} alt="" />
          <img src={img55} alt="" />
          <img src={img66} alt="" />
        </div>
        <div>
          <img src={img1} alt="" />
          <img src={img2} alt="" />
          <img src={img3} alt="" />
          <img src={img4} alt="" />
          <img src={img5} alt="" />
          <img src={img6} alt="" />
        </div>
      </div>
    </>
  );
}

export default MintSlider;
