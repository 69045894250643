import React, { useState } from "react";
import hamburgericon from "../../assets/icons/hamburgericon.svg";
import xclose from "../../assets/icons/xclose.svg";
import "./Navbar.css";
import { Link } from "react-router-dom";
import "antd/lib/drawer/style/index";
import discord from "../../assets/icons/discord.svg";
import instagram from "../../assets/icons/instagram.svg";
import twitter from "../../assets/icons/xmobile.svg";
import linkedin from "../../assets/icons/linkedinr.svg";
import reddit from "../../assets/icons/redditr.svg";
import telegram from "../../assets/icons/telegramr.svg";
import medium from "../../assets/icons/mediumr.svg";
import SOCIALS from "../../config/socials.json";
import { Drawer, Button } from "antd";
import pdf from "./../../assets/whitepaper/whitepaper.pdf";

function Hamburger() {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <Button onClick={showDrawer} className="hamb-menu">
        <img
          style={{ marginTop: "auto", marginBottom: "auto", height: "20px" }}
          src={hamburgericon}
          alt=""
        />
      </Button>
      <Drawer
        placement="right"
        closeIcon={<img style={{ height: "30px" }} src={xclose} alt="" />}
        onClose={onClose}
        open={visible}>
        <div className="hamb-menuli">
          <Link className="navLink" aria-current="page" to="/">
            Home
          </Link>
          <Link className="navLink" aria-current="page" to="/team">
            Team
          </Link>
          <Link className="navLink" aria-current="page" to="/mint">
            Mint
          </Link>
          <Link
            className="navLink"
            aria-current="page"
            to={pdf}
            target="_blank"
            rel="noreferrer">
            Whitepaper
          </Link>
          <Link className="navLink" aria-current="page" to="/contact">
            Contact
          </Link>
          <Link className="navLink" aria-current="page" to="/news">
            News & Articles
          </Link>
          <Link className="navLink" aria-current="page" to="/press">
            Press Release
          </Link>
          <div className="nav-social">
            <a href={SOCIALS.discord} target="_blank" rel="noreferrer">
              <img className="nav-img" src={discord} alt="" />
            </a>
            <a href={SOCIALS.twitter} target="_blank" rel="noreferrer">
              <img className="nav-img" src={twitter} alt="" />
            </a>
            <a href={SOCIALS.instagram} target="_blank" rel="noreferrer">
              <img className="nav-img" src={instagram} alt="" />
            </a>
            <a href={SOCIALS.linkedin} target="_blank" rel="noreferrer">
              <img className="nav-img" src={linkedin} alt="" />
            </a>
            <a href={SOCIALS.reddit} target="_blank" rel="noreferrer">
              <img className="nav-img" src={reddit} alt="" />
            </a>
            <a href={SOCIALS.telegram} target="_blank" rel="noreferrer">
              <img className="nav-img" src={telegram} alt="" />
            </a>
            <a href={SOCIALS.medium} target="_blank" rel="noreferrer">
              <img className="nav-img" src={medium} alt="" />
            </a>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default Hamburger;
